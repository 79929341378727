import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
  render() {
    const token = localStorage.getItem('token');

    return (
      <footer className={this.props.className}>
        <div>
          {window.innerWidth >= 768 && (
            <div className='mt-3' style={{ paddingTop: '20px', margin: '0 15px' }}>
              <Row className='c-font-xs' style={{ width: '100%', margin: '0', padding: '10px 60px', textAlign: 'center', direction: 'rtl' }}>
                <Col style={{ width: '33%', textAlign: 'right' }}>
                  <p className='c-font-sm' style={{ fontWeight: 'bold' }}>
                    {' طلا و جواهرات رضوان '}
                  </p>
                  <Link to={{ pathname: '/', state: { filter: { type: 'sort_by', val: 'new' } } }}>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' خانه '}</span>
                  </Link>
                  <br />
                  <Link to='/store'>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' فروشگاه '}</span>
                  </Link>
                  <br />
                  <Link to='/cart'>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' سبدخرید '}</span>
                  </Link>
                  <br />
                  {/*<Link to="/brands">*/}
                  {/*    <span style={{color: 'grey', display: 'inline-block', marginBottom: '7px'}}>{' برندها '}</span>*/}
                  {/*</Link>*/}
                  <br />
                  <Link to='/about'>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' درباره ما '}</span>
                  </Link>
                  <br />
                  <Link to='/contact'>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' تماس با ما '}</span>
                  </Link>
                  <br />
                  <Link to='/policy'>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' قوانین و مقررات '}</span>
                  </Link>
                  <br />
                  <Link to={localStorage.getItem('token') ? '/profile/new-ticket' : '/login'}>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' پشتیبانی '}</span>
                  </Link>
                </Col>
                <Col style={{ width: '33%', textAlign: 'right' }}>
                  <p className='c-font-sm' style={{ fontWeight: 'bold' }}>
                    {' حساب کاربری '}
                  </p>
                  <Link to='/login'>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' ورود '}</span>
                  </Link>
                  <br />
                  <Link to='/register'>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' ثبت‌نام '}</span>
                  </Link>
                  <br />
                  <Link to={token ? '/profile' : '/login'}>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' پروفایل '}</span>
                  </Link>
                  <br />
                  <Link to={token ? '/profile/orders' : '/login'}>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' سفارش‌های من '}</span>
                  </Link>
                </Col>
                <Col style={{ width: '33%', textAlign: 'right' }}>
                  <p className='c-font-sm' style={{ fontWeight: 'bold' }}>
                    {' شبکه‌های اجتماعی '}
                  </p>
                  <a href={'https://www.instagram.com/rezvan_jewellery'} target='_blank' rel='noopener noreferrer'>
                    <i className='fab fa-instagram fa-1x'></i>
                    <span style={{ color: 'grey', display: 'inline-block', margin: '0 7px 7px 0' }}>{' اینستاگرام '}</span>
                  </a>
                  <br />
                  <a href={'https://t.me/Abehbahani'} target='_blank' rel='noopener noreferrer'>
                    <i className='fab fa-telegram fa-1x'></i>
                    <span style={{ color: 'grey', display: 'inline-block', margin: '0 7px 7px 0' }}>{' تلگرام '}</span>
                  </a>
                  <br />
                  <a href={'https://wa.me/+989121277336'} target='_blank' rel='noopener noreferrer'>
                    <i className='fab fa-whatsapp fa-1x' style={{ color: '#25d366' }}></i>
                    <span style={{ color: 'grey', display: 'inline-block', margin: '0 7px 7px 0' }}>{' واتساپ '}</span>
                  </a>

                  <br />

                  <a referrerPolicy='origin' target='_blank' href='https://trustseal.enamad.ir/?id=441728&Code=DDAbyo3te49lXp4egITnvWs9oW5xqYUd'>
                    <img
                      referrerPolicy='origin'
                      src='https://trustseal.enamad.ir/logo.aspx?id=441728&Code=DDAbyo3te49lXp4egITnvWs9oW5xqYUd'
                      alt=''
                      style={{ cursor: 'pointer' }}
                      id='DDAbyo3te49lXp4egITnvWs9oW5xqYUd'
                    />
                  </a>
                </Col>
                {/*<Col style={{ width: '25%', textAlign: 'right' }}>*/}
                {/* <div className="newsletter">
                                <p className="c-font-sm" style={{fontWeight: 'bold' }}>{' عضويت خبرنامه '}</p>
                                <div className="block" style={{direction: 'ltr'}}>
                                    <Form.Row>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text className="main-bg-color" id="inputGroupPrepend">
                                                    <FontAwesomeIcon icon={faEnvelope} />
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                className="c-font-xs rounded-right"
                                                type="text"
                                                dir="rtl"
                                                placeholder={"ایمیل خود را وارد کنید"}
                                                aria-describedby="inputGroupPrepend"
                                                name="username"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                //errors should be here
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Row>
                                </div>
                            </div>
                            <br />
                            <br /> */}
                {/*<p className="c-font-sm" style={{fontWeight: 'bold' }}>{' مجوزها '}</p>*/}
                {/*<div className="enamad rtl">*/}
                {/*    /!* <a target="_blank" rel="noopener noreferrer" href="https://trustseal.enamad.ir/?id=108226&amp;Code=s6ICzg6uKG3TXYg1jJnC"> *!/*/}
                {/*    /!* <a referrerPolicy="origin" target="_blank" rel="noopener noreferrer" href="https://trustseal.enamad.ir/?id=108226&amp;Code=s6ICzg6uKG3TXYg1jJnC"> *!/*/}
                {/*        /!* <img src="https://Trustseal.eNamad.ir/logo.aspx?id=108226&amp;Code=s6ICzg6uKG3TXYg1jJnC" width={"120px"} alt="" style={{ cursor: "pointer", float: "left" }} id="s6ICzg6uKG3TXYg1jJnC" /></a> *!/*/}
                {/*        <a referrerpolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=108226&amp;Code=s6ICzg6uKG3TXYg1jJnC"><img referrerpolicy="origin" width="120px" style={{cursor: "pointer", float: "left"}} src="https://Trustseal.eNamad.ir/logo.aspx?id=108226&amp;Code=s6ICzg6uKG3TXYg1jJnC" alt="" id="s6ICzg6uKG3TXYg1jJnC"/></a>*/}
                {/*        /!* <img */}
                {/*            referrerPolicy="origin"*/}
                {/*            src="https://Trustseal.eNamad.ir/logo.aspx?id=108226&amp;Code=s6ICzg6uKG3TXYg1jJnC"*/}
                {/*            alt=""*/}
                {/*            onClick='window.open("https://trustseal.enamad.ir/?id=19077&amp;Code=sScdOJOzhFxtcEqkjP7P")'*/}
                {/*            id="s6ICzg6uKG3TXYg1jJnC"*/}
                {/*            style={{ cursor: "pointer", float: "left" }}*/}
                {/*            width={"130px"}*/}
                {/*        /> *!/*/}
                {/*    /!* </a> *!/*/}
                {/*</div>*/}
                {/*<div className="enamad rtl">*/}
                {/*    /!* <a target="_blank" rel="noopener noreferrer" href="https://ecunion.ir/verify/rezvanjewellery.com?token=6101858604f2bd9167bf"> *!/*/}
                {/*    /!* <a rel="noopener noreferrer" target="_blank" href="https://ecunion.ir/landing/2505/business">*/}
                {/*        <img*/}
                {/*            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQwIiBoZWlnaHQ9IjM2MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KCTxwYXRoIGQ9Im0xMjAgMjQzbDk0LTU0IDAtMTA5IC05NCA1NCAwIDEwOSAwIDB6IiBmaWxsPSIjODA4Mjg1Ii8+Cgk8cGF0aCBkPSJtMTIwIDI1NGwtMTAzLTYwIDAtMTE5IDEwMy02MCAxMDMgNjAgMCAxMTkgLTEwMyA2MHoiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS13aWR0aDo1O3N0cm9rZTojMDBhZWVmIi8+Cgk8cGF0aCBkPSJtMjE0IDgwbC05NC01NCAtOTQgNTQgOTQgNTQgOTQtNTR6IiBmaWxsPSIjMDBhZWVmIi8+Cgk8cGF0aCBkPSJtMjYgODBsMCAxMDkgOTQgNTQgMC0xMDkgLTk0LTU0IDAgMHoiIGZpbGw9IiM1ODU5NWIiLz4KCTxwYXRoIGQ9Im0xMjAgMTU3bDQ3LTI3IDAtMjMgLTQ3LTI3IC00NyAyNyAwIDU0IDQ3IDI3IDQ3LTI3IiBzdHlsZT0iZmlsbDpub25lO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2Utd2lkdGg6MTU7c3Ryb2tlOiNmZmYiLz4KCTx0ZXh0IHg9IjE1IiB5PSIzMDAiIGZvbnQtc2l6ZT0iMjVweCIgZm9udC1mYW1pbHk9IidCIFlla2FuJyIgc3R5bGU9ImZpbGw6IzI5Mjk1Mjtmb250LXdlaWdodDpib2xkIj7Yudi22Ygg2KfYqtit2KfYr9uM2Ycg2qnYtNmI2LHbjDwvdGV4dD4KCTx0ZXh0IHg9IjgiIHk9IjM0MyIgZm9udC1zaXplPSIyNXB4IiBmb250LWZhbWlseT0iJ0IgWWVrYW4nIiBzdHlsZT0iZmlsbDojMjkyOTUyO2ZvbnQtd2VpZ2h0OmJvbGQiPtqp2LPYqCDZiCDaqdin2LHZh9in24wg2YXYrNin2LLbjDwvdGV4dD4KPC9zdmc+ "*/}
                {/*            alt=""*/}
                {/*            width={"100px"}*/}
                {/*            style={{ cursor: "pointer", float: "left" }}*/}
                {/*            id="s6ICzg6uKG3TXYg1jJnC"*/}
                {/*        />*/}
                {/*    </a> *!/*/}
                {/*    <img*/}
                {/*        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQwIiBoZWlnaHQ9IjM2MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KCTxwYXRoIGQ9Im0xMjAgMjQzbDk0LTU0IDAtMTA5IC05NCA1NCAwIDEwOSAwIDB6IiBmaWxsPSIjODA4Mjg1Ii8+Cgk8cGF0aCBkPSJtMTIwIDI1NGwtMTAzLTYwIDAtMTE5IDEwMy02MCAxMDMgNjAgMCAxMTkgLTEwMyA2MHoiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS13aWR0aDo1O3N0cm9rZTojMDBhZWVmIi8+Cgk8cGF0aCBkPSJtMjE0IDgwbC05NC01NCAtOTQgNTQgOTQgNTQgOTQtNTR6IiBmaWxsPSIjMDBhZWVmIi8+Cgk8cGF0aCBkPSJtMjYgODBsMCAxMDkgOTQgNTQgMC0xMDkgLTk0LTU0IDAgMHoiIGZpbGw9IiM1ODU5NWIiLz4KCTxwYXRoIGQ9Im0xMjAgMTU3bDQ3LTI3IDAtMjMgLTQ3LTI3IC00NyAyNyAwIDU0IDQ3IDI3IDQ3LTI3IiBzdHlsZT0iZmlsbDpub25lO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2Utd2lkdGg6MTU7c3Ryb2tlOiNmZmYiLz4KCTx0ZXh0IHg9IjE1IiB5PSIzMDAiIGZvbnQtc2l6ZT0iMjVweCIgZm9udC1mYW1pbHk9IidCIFlla2FuJyIgc3R5bGU9ImZpbGw6IzI5Mjk1Mjtmb250LXdlaWdodDpib2xkIj7Yudi22Ygg2KfYqtit2KfYr9uM2Ycg2qnYtNmI2LHbjDwvdGV4dD4KCTx0ZXh0IHg9IjgiIHk9IjM0MyIgZm9udC1zaXplPSIyNXB4IiBmb250LWZhbWlseT0iJ0IgWWVrYW4nIiBzdHlsZT0iZmlsbDojMjkyOTUyO2ZvbnQtd2VpZ2h0OmJvbGQiPtqp2LPYqCDZiCDaqdin2LHZh9in24wg2YXYrNin2LLbjDwvdGV4dD4KPC9zdmc+ "*/}
                {/*        alt="ecunion-logo"*/}
                {/*        onClick={() => window.open('https://ecunion.ir/verify/rezvanjewellery.com?token=6101858604f2bd9167bf', 'Popup','toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30')}*/}
                {/*        style={{cursor: "pointer", width: "96px", height: "144px"}}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*</Col>*/}
              </Row>
              {/* <Row className="c-font-xs" style={{ width: '90%', padding: '20px', margin: '0 auto', textAlign: 'center', borderTop: '0.5px solid lightgrey'}}>
                        <div style={{ width: '100%', direction: 'rtl' }}>
                            <FontAwesomeIcon className="ml-1 color-black" icon={faPhone} style={{width: '.8em'}}/>
                            <span>{'شماره تلفن:  '}</span>
                            <a style={{ color: 'grey', paddingLeft: '40px' }} href="tel:02191312001"> {'02191312001'} </a>
                            <FontAwesomeIcon className="ml-1 color-black" icon={faEnvelope} style={{width: '.8em'}}/>
                            <span>{'ایمیل:  '}</span>
                            <a style={{ color: 'grey' }} href="mailto:info@rezvanjewellery.com">{'info@rezvanjewellery.com'}</a>
                        </div>
                    </Row> */}
            </div>
          )}

          {window.innerWidth < 768 && (
            <div className='mt-3' style={{ paddingTop: '20px', margin: '0 15px' }}>
              <Row className='c-font-md' style={{ width: '100%', margin: '0', padding: '10px', textAlign: 'center', backgroundColor: '#f5f5f5', borderRadius: '20px' }}>
                <div style={{ width: '100%' }}>
                  <p style={{ textAlign: 'center' }}>{': ما را در شبکه‌های اجتماعی دنبال کنید'}</p>
                </div>
                <div style={{ width: '100%' }}>
                  <a style={{ padding: '0px 10px' }} href={'https://wa.me/+989121277336'} target='_blank' rel='noopener noreferrer'>
                    <i className='fab fa-whatsapp fa-3x' style={{ color: '#25d366' }}></i>
                  </a>
                  <a style={{ padding: '0px 10px' }} href={'https://t.me/Abehbahani'} target='_blank' rel='noopener noreferrer'>
                    <i className='fab fa-telegram fa-3x '></i>
                  </a>
                  <a style={{ padding: '0px 10px' }} href={'https://www.instagram.com/rezvan_jewellery'} target='_blank' rel='noopener noreferrer'>
                    <i className='fab fa-instagram fa-3x '></i>
                  </a>

                  <br />
                  <br />

                  <a referrerPolicy='origin' target='_blank' href='https://trustseal.enamad.ir/?id=441728&Code=DDAbyo3te49lXp4egITnvWs9oW5xqYUd'>
                    <img
                      referrerPolicy='origin'
                      src='https://trustseal.enamad.ir/logo.aspx?id=441728&Code=DDAbyo3te49lXp4egITnvWs9oW5xqYUd'
                      alt=''
                      style={{ cursor: 'pointer' }}
                      id='DDAbyo3te49lXp4egITnvWs9oW5xqYUd'
                    />
                  </a>
                </div>
              </Row>
              <Row className='c-font-md' style={{ width: '100%', margin: '0', padding: '10px', marginTop: '1rem' }}>
                <Col style={{ width: '50%', textAlign: 'right' }}>
                  <p className='c-font-md' style={{ fontWeight: 'bold', textAlign: 'right' }}>
                    {' حساب کاربری '}
                  </p>
                  <Link to='/login'>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' ورود '}</span>
                  </Link>
                  <br />
                  <Link to='/register'>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' ثبت‌نام '}</span>
                  </Link>
                  <br />
                  <Link to={token ? '/profile' : '/login'}>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' پروفایل '}</span>
                  </Link>
                  <br />
                  <Link to={token ? '/profile/orders' : '/login'}>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' سفارش‌های من '}</span>
                  </Link>
                </Col>
                <Col style={{ width: '50%', textAlign: 'right' }}>
                  <p className='c-font-md' style={{ fontWeight: 'bold', textAlign: 'right' }}>
                    {' طلا و جواهرات رضوان '}
                  </p>
                  <Link to={{ pathname: '/', state: { filter: { type: 'sort_by', val: 'new' } } }}>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' خانه '}</span>
                  </Link>
                  <br />
                  <Link to='/store'>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' فروشگاه '}</span>
                  </Link>
                  <br />
                  <Link to='/cart'>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' سبدخرید '}</span>
                  </Link>
                  <br />
                  <Link to='/brands'>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' برندها '}</span>
                  </Link>
                  <br />
                  <Link to='/about'>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' درباره ما '}</span>
                  </Link>
                  <br />
                  <Link to='/contact'>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' تماس با ما '}</span>
                  </Link>
                  <br />
                  <Link to='/policy'>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' قوانین و مقررات '}</span>
                  </Link>
                  <br />
                  <Link to={localStorage.getItem('token') ? '/profile/new-ticket' : '/login'}>
                    <span style={{ color: 'grey', display: 'inline-block', marginBottom: '7px' }}>{' پشتیبانی '}</span>
                  </Link>
                </Col>
              </Row>
              {/*<Row className="c-font-md" style={{ width: '100%', margin: '0', padding: '10px 25px', borderTop: '0.5px solid lightgrey' }}>*/}
              {/*<Col style={{ width: '50%' }}>*/}
              {/*    <div className="enamad rtl">*/}
              {/*        /!* <a target="_blank" rel="noopener noreferrer" href="https://trustseal.enamad.ir/?id=108226&amp;Code=s6ICzg6uKG3TXYg1jJnC"> *!/*/}
              {/*        /!* <a referrerPolicy="origin" target="_blank" rel="noopener noreferrer" href="https://trustseal.enamad.ir/?id=108226&amp;Code=s6ICzg6uKG3TXYg1jJnC"> *!/*/}
              {/*            /!* <img src="https://Trustseal.eNamad.ir/logo.aspx?id=108226&amp;Code=s6ICzg6uKG3TXYg1jJnC" width={"130px"} alt="" style={{ cursor: "pointer", float: "left" }} id="s6ICzg6uKG3TXYg1jJnC" /></a> *!/*/}
              {/*            /!* <img */}
              {/*                referrerPolicy="origin"*/}
              {/*                src="https://Trustseal.eNamad.ir/logo.aspx?id=108226&amp;Code=s6ICzg6uKG3TXYg1jJnC"*/}
              {/*                alt=""*/}
              {/*                onClick='window.open("https://trustseal.enamad.ir/?id=19077&amp;Code=sScdOJOzhFxtcEqkjP7P")'*/}
              {/*                id="s6ICzg6uKG3TXYg1jJnC"*/}
              {/*                style={{ cursor: "pointer", float: "left" }}*/}
              {/*                width={"130px"}*/}
              {/*            />*/}
              {/*        </a> *!/*/}

              {/*        /!* <a referrerpolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=108226&amp;Code=s6ICzg6uKG3TXYg1jJnC">*/}
              {/*            <img referrerpolicy="origin" src="https://Trustseal.eNamad.ir/logo.aspx?id=108226&amp;Code=s6ICzg6uKG3TXYg1jJnC" alt="" style="cursor:pointer" id="s6ICzg6uKG3TXYg1jJnC" width={"130px"} style={{ cursor: "pointer", float: "left" }}/>*/}
              {/*        </a> *!/*/}
              {/*        <a referrerpolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=108226&amp;Code=s6ICzg6uKG3TXYg1jJnC"><img referrerpolicy="origin" src="https://Trustseal.eNamad.ir/logo.aspx?id=108226&amp;Code=s6ICzg6uKG3TXYg1jJnC" alt="" width="120px" style={{cursor: "pointer", float: "left"}} id="s6ICzg6uKG3TXYg1jJnC"/></a>*/}
              {/*    </div>*/}
              {/*</Col>*/}
              {/* <Col style={{ width: '100%', direction: 'rtl', textAlign: 'center', padding: '20px 0' }}> */}
              {/*<Col style={{ width: '50%' }}>*/}
              {/*    <div className="enamad rtl">*/}
              {/*        /!* <a target="_blank" rel="noopener noreferrer" href="https://ecunion.ir/verify/rezvanjewellery.com?token=6101858604f2bd9167bf"> *!/*/}
              {/*        /!* <a rel="noopener noreferrer" target="_blank" href="https://ecunion.ir/landing/2505/business">*/}
              {/*            <img*/}
              {/*                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQwIiBoZWlnaHQ9IjM2MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KCTxwYXRoIGQ9Im0xMjAgMjQzbDk0LTU0IDAtMTA5IC05NCA1NCAwIDEwOSAwIDB6IiBmaWxsPSIjODA4Mjg1Ii8+Cgk8cGF0aCBkPSJtMTIwIDI1NGwtMTAzLTYwIDAtMTE5IDEwMy02MCAxMDMgNjAgMCAxMTkgLTEwMyA2MHoiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS13aWR0aDo1O3N0cm9rZTojMDBhZWVmIi8+Cgk8cGF0aCBkPSJtMjE0IDgwbC05NC01NCAtOTQgNTQgOTQgNTQgOTQtNTR6IiBmaWxsPSIjMDBhZWVmIi8+Cgk8cGF0aCBkPSJtMjYgODBsMCAxMDkgOTQgNTQgMC0xMDkgLTk0LTU0IDAgMHoiIGZpbGw9IiM1ODU5NWIiLz4KCTxwYXRoIGQ9Im0xMjAgMTU3bDQ3LTI3IDAtMjMgLTQ3LTI3IC00NyAyNyAwIDU0IDQ3IDI3IDQ3LTI3IiBzdHlsZT0iZmlsbDpub25lO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2Utd2lkdGg6MTU7c3Ryb2tlOiNmZmYiLz4KCTx0ZXh0IHg9IjE1IiB5PSIzMDAiIGZvbnQtc2l6ZT0iMjVweCIgZm9udC1mYW1pbHk9IidCIFlla2FuJyIgc3R5bGU9ImZpbGw6IzI5Mjk1Mjtmb250LXdlaWdodDpib2xkIj7Yudi22Ygg2KfYqtit2KfYr9uM2Ycg2qnYtNmI2LHbjDwvdGV4dD4KCTx0ZXh0IHg9IjgiIHk9IjM0MyIgZm9udC1zaXplPSIyNXB4IiBmb250LWZhbWlseT0iJ0IgWWVrYW4nIiBzdHlsZT0iZmlsbDojMjkyOTUyO2ZvbnQtd2VpZ2h0OmJvbGQiPtqp2LPYqCDZiCDaqdin2LHZh9in24wg2YXYrNin2LLbjDwvdGV4dD4KPC9zdmc+ "*/}
              {/*                alt=""*/}
              {/*                width={"100px"}*/}
              {/*                style={{ cursor: "pointer", float: "left" }}*/}
              {/*                id="s6ICzg6uKG3TXYg1jJnC"*/}
              {/*            />*/}
              {/*        </a> *!/*/}
              {/*        <img*/}
              {/*            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQwIiBoZWlnaHQ9IjM2MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KCTxwYXRoIGQ9Im0xMjAgMjQzbDk0LTU0IDAtMTA5IC05NCA1NCAwIDEwOSAwIDB6IiBmaWxsPSIjODA4Mjg1Ii8+Cgk8cGF0aCBkPSJtMTIwIDI1NGwtMTAzLTYwIDAtMTE5IDEwMy02MCAxMDMgNjAgMCAxMTkgLTEwMyA2MHoiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS13aWR0aDo1O3N0cm9rZTojMDBhZWVmIi8+Cgk8cGF0aCBkPSJtMjE0IDgwbC05NC01NCAtOTQgNTQgOTQgNTQgOTQtNTR6IiBmaWxsPSIjMDBhZWVmIi8+Cgk8cGF0aCBkPSJtMjYgODBsMCAxMDkgOTQgNTQgMC0xMDkgLTk0LTU0IDAgMHoiIGZpbGw9IiM1ODU5NWIiLz4KCTxwYXRoIGQ9Im0xMjAgMTU3bDQ3LTI3IDAtMjMgLTQ3LTI3IC00NyAyNyAwIDU0IDQ3IDI3IDQ3LTI3IiBzdHlsZT0iZmlsbDpub25lO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2Utd2lkdGg6MTU7c3Ryb2tlOiNmZmYiLz4KCTx0ZXh0IHg9IjE1IiB5PSIzMDAiIGZvbnQtc2l6ZT0iMjVweCIgZm9udC1mYW1pbHk9IidCIFlla2FuJyIgc3R5bGU9ImZpbGw6IzI5Mjk1Mjtmb250LXdlaWdodDpib2xkIj7Yudi22Ygg2KfYqtit2KfYr9uM2Ycg2qnYtNmI2LHbjDwvdGV4dD4KCTx0ZXh0IHg9IjgiIHk9IjM0MyIgZm9udC1zaXplPSIyNXB4IiBmb250LWZhbWlseT0iJ0IgWWVrYW4nIiBzdHlsZT0iZmlsbDojMjkyOTUyO2ZvbnQtd2VpZ2h0OmJvbGQiPtqp2LPYqCDZiCDaqdin2LHZh9in24wg2YXYrNin2LLbjDwvdGV4dD4KPC9zdmc+ "*/}
              {/*            alt="ecunion-logo"*/}
              {/*            onClick={() => window.open('https://ecunion.ir/verify/rezvanjewellery.com?token=6101858604f2bd9167bf', 'Popup','toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30')}*/}
              {/*            style={{cursor: "pointer", width: "96px", height: "144px"}}*/}
              {/*        />*/}
              {/*    </div>*/}
              {/*    /!* <div style={{ width: '100%', padding: '0 0 5px 0' }}>*/}
              {/*        <i style={{ fontStyle: 'normal' }}>*/}
              {/*            <FontAwesomeIcon className="ml-1 color-black" icon={faPhone} />*/}
              {/*            <a style={{ color: 'grey' }} href="tel:02191312001"> {'021-91312001'} </a>*/}
              {/*        </i>*/}
              {/*    </div> *!/*/}
              {/*    /!* <div style={{ width: '100%' }}>*/}
              {/*        <i style={{ fontStyle: 'normal' }}>*/}
              {/*            <FontAwesomeIcon className="ml-1 color-black" icon={faEnvelope} />*/}
              {/*            <a style={{ color: 'grey' }} href="mailto:info@rezvanjewellery.com"> info@rezvanjewellery.com </a>*/}
              {/*        </i>*/}
              {/*    </div> *!/*/}
              {/*</Col>*/}
              {/* <Row className="c-font-md" style={{ width: '100%', margin: '0', padding: '10px 25px', borderTop: '0.5px solid lightgrey' }}>
                            <Col style={{ width: '50%', direction: 'rtl', textAlign: 'right', padding: '40px 0' }}>
                                <div style={{ width: '100%' }}>
                                    <i style={{ fontStyle: 'normal' }}>
                                        <FontAwesomeIcon className="ml-1 color-black" icon={faEnvelope} />
                                        <a style={{ color: 'grey' }} href="mailto:info@rezvanjewellery.com"> info@rezvanjewellery.com </a>
                                    </i>
                                </div>
                            </Col>
                        </Row> */}
              {/*</Row>*/}
            </div>
          )}

          <Row className='footer-last-row' style={{ fontSize: '11px', width: '100%', margin: '0' }}>
            <p style={{ margin: '0 auto', padding: '10px 0' }}>{'کلیه حقوق این سایت متعلق به فروشگاه آنلاین طلا و جواهرات رضوان می‌باشد'}</p>
          </Row>
        </div>
      </footer>
    );
  }
}

export default Footer;
