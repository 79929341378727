import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import { Modal, Button } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { conf } from './../confg';
import { Player } from 'video-react';

export default function InfoModal ({ showModal, onHide }) {
  const [data, setData] = useState();
  useEffect(() => {
    axios({
      url: conf.server_url + 'events',
      method: 'GET',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then(({ data }) => {
      setData(data);
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const extractTextFromHTML = html => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };

  // const text = extractTextFromHTML(htmlContent);
  if (!data) {
    return null;
  }
  return (
    <Modal show={showModal} onHide={onHide} style={{ zIndex: '10000000000' }}>
      <Modal.Body className={'rtl'}>
        {data?.media?.length > 1 ? (
          <Slider {...settings}>
            {data.media.map(e =>
              e.mime_type === 'video/webm' ? (
                <Player>
                  <source
                    // poster='https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg'
                    autofocus
                    src={e.file_name}
                  />
                </Player>
              ) : (
                <img src={e.file_name} />
              )
            )}
          </Slider>
        ) : (
          data?.media?.map(e =>
            e.mime_type === 'video/webm' ? (
              <Player>
                <source
                  // poster='https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg'
                  autofocus
                  src={e.file_name}
                />
              </Player>
            ) : (
              <img src={e.file_name} />
            )
          )
        )}
        <div style={{ textAlign: 'center' }}>
          {<h2>{data?.name}</h2>}
          {extractTextFromHTML(data?.message)}
          {/* {data?.message} */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className='btn-success' variant='secondary' onClick={onHide} style={{ border: '0px', width: '49%', color: '073054', margin: '0 auto' }}>
          متوجه شدم
        </Button>
        {data?.url && (
          <Button className='btn-success' variant='secondary' style={{ border: '0px', width: '49%', color: '073054', margin: '0 auto' }}>
            <a href={data?.url}>مشاهده</a>
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
