import React from 'react';
import { Row, Container, Col, Image, Nav, Navbar, NavDropdown, Dropdown, InputGroup, ButtonToolbar, Collapse } from 'react-bootstrap';
import { Link, Redirect, withRouter } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import {
  faBars,
  faCaretLeft,
  faPlus,
  faMinus,
  faLayerGroup,
  faPhone,
  faHeadset,
  faPeopleGroup,
  faHome,
  faShoppingBag,
  faSearch,
  faShoppingCart,
  faUser,
  faUserCheck,
  faChevronLeft
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';

import { product_list_localstorage } from '../confg';
import { notNullAndUndefined } from '../helper';
import { logout_user, set_me, set_user } from '../actions/user';
import { getBasket, mandeHesab_setter } from '../actions/cartActions';
import { subcategory_setter } from '../actions/subcat';
import { set_all_brands } from '../actions/brands';
import { set_all_categories } from '../actions/categories';
import { errorAction } from '../actions/request_error';
import '../assets/css/custom.css';
import logo from '../assets/images/logo.png';
import slide_logo from '../assets/images/logoslide.png';
import { convertPersianNumberToEnglish } from '../utils/utils';

class AppBar extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      user: {},
      goToLogin: false,
      searchText: this.props.filters?.text || '',
      goToCart: false,
      width: 0,
      display: 'none',
      open: false,
      categories: [],
      brands: [],
      gold: this.props.gold,
      mojoodi: 0,
      openCategory: false,
      openBrand: false
    };
  }

  componentDidMount () {
    this.props.getBasket();
    this.props.set_all_brands();
    this.props.set_all_categories();
    this.props.mandeHesab_setter();

    let tok = localStorage.getItem('token');

    if (Object.keys(this.props.user).length > 0) {
      this.setState({
        user: this.props.user
      });
    } else {
      if (notNullAndUndefined(tok)) {
        this.props.set_me();
      }
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { brands, categories } = this.props;

    if (prevProps.location.key !== this.props.location.key) {
      this.setState({ ...this.state, searchText: '' });
    }

    if (prevProps.errors !== this.props.errors) {
      if (this.props.user !== prevProps.user) {
        this.setState({
          user: this.props.user
        });
      }
    }

    if (this.props.user !== prevProps.user) {
      this.setState({
        user: this.props.user
      });
    }

    if (prevProps.brands !== this.props.brands) {
      this.setState({
        brands: brands
      });
    }

    if (prevProps.categories !== this.props.categories) {
      this.setState({
        categories: categories
      });
    }
  }

  openSideBar (e) {
    this.state.width > 0 ? this.setState({ width: 0, display: 'none' }) : this.setState({ width: 70, display: 'block' });
  }

  logout () {
    localStorage.setItem('token', undefined);
    this.props.logout_user();

    this.setState({
      goToLogin: true
    });
  }

  handleKeyPress (target) {
    let s = target.target.value;
    let PersianOrASCII = /[آ-ی]|([a-zA-Z])/;
    let m = s.match(PersianOrASCII);

    let searchText = this.state.searchText && this.state.searchText.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d)).replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));

    if (m !== null) {
      if (m[1]) {
        target.preventDefault();
        target.stopPropagation();
        this.props.errorAction('لطفا فارسی تایپ کنید');
        this.setState({
          searchText: ''
        });
      } else {
        if (target.key === 'Enter') {
          this.props.history.push('/store', { filter: { type: 'text', val: searchText }, state: window.location.pathname });
        }
      }
    } else {
      if (target.key === 'Enter') {
        this.props.history.push('/store', { filter: { type: 'text', val: searchText }, state: window.location.pathname });
      }
    }
  }

  isNumeric (value) {
    if (!/^-?\d+$/.test(value)) {
      return this.props.translate(value);
    } else {
      return this.props.translate('product');
    }
  }

  render () {
    let { user, translate } = this.props;
    const { openCategory, openBrand, categories } = this.state;
    let productStorage = localStorage.getItem(product_list_localstorage);
    let location = window.location.pathname.split('/');
    let products_number =
      Object.keys(user).length === 0
        ? productStorage && notNullAndUndefined(JSON.parse(productStorage)) && JSON.parse(localStorage.getItem(product_list_localstorage)).length
        : user['is_teammate'] !== 1 && notNullAndUndefined(this.props.cart.cart.products)
        ? this.props.cart.cart.products.length
        : 0;

    if (this.state.goToLogin) {
      return <Redirect to='/login' />;
    }

    if (this.state.goToCart) {
      return <Redirect push to='/cart' />;
    }

    return (
      <div className={window.innerWidth >= 768 ? 'appbar-sticky' : ''}>
        {/* top warning */}
        {/* <div className="top-warning">
                    <p>{this.props.translate("warning_top")}</p>
                </div> */}

        <div className={'mt-sm-0 c-header header ' + this.props.className}>
          <Container fluid={true}>
            <Row className='align-items-center no-gutters lg-gutters'>
              <Col xs={5} md={5} lg={4}>
                <ButtonToolbar>
                  {/* cart button on desktop */}
                  <Link to={'/cart'} className={'d-none d-sm-block c-font-xs max-vw-90 btn my-1 btn btn-outline-dark'}>
                    {this.props.translate('cart')}
                    <FontAwesomeIcon className={this.props.cart.cart.products.length > 0 ? 'success-color ml-1' : 'grey-color ml-1'} icon={faShoppingCart} />
                    {Object.keys(user).length > 0 && user['is_teammate'] === 1 ? (
                      <span className='badge badge-danger ml-2 dir-rtl' style={{ bottom: '31px' }}>
                        <Translate id='weight_v2' data={{ weight: this.props.cart.cart_report.total_weight }} />
                      </span>
                    ) : (
                      <span className='badge badge-danger ml-2'>{products_number}</span>
                    )}
                  </Link>

                  {/* profile icon on mobile */}
                  {Object.keys(user).length > 0 ? (
                    user['is_teammate'] === 1 ? (
                      <Link to={Object.keys(this.state.user).length === 0 ? '/login' : '/profile'}>
                        <FontAwesomeIcon size='2x' className='d-sm-none success-color mr-1 mobile-profile-icon' icon={faUserCheck} />
                      </Link>
                    ) : (
                      <Link to={Object.keys(this.state.user).length === 0 ? '/login' : '/profile'}>
                        <FontAwesomeIcon size='2x' className='d-sm-none success-color mr-1 mobile-profile-icon' icon={faUser} />
                      </Link>
                    )
                  ) : (
                    <Link to={Object.keys(this.state.user).length === 0 ? '/login' : '/profile'}>
                      <FontAwesomeIcon size='2x' className='d-sm-none grey-color mr-1 mobile-profile-icon' icon={faUser} />
                    </Link>
                  )}

                  {/* profile button on desktop */}
                  <Dropdown className='d-none d-sm-block p-1'>
                    <Dropdown.Toggle className='btn max-vw-90 c-font-xs' variant='outline-dark' id='dropdown-basic'>
                      {Object.keys(user).length > 0
                        ? this.props.user.name !== null
                          ? this.props.user.name + ' ' + this.props.user.family
                          : this.props.user.mobile
                        : this.props.translate('login') + '/' + this.props.translate('register')}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Object.keys(this.props.user).length > 0 ? (
                        <>
                          <Dropdown.Item className={'c-font-xs'} onClick={this.logout.bind(this)}>
                            {this.props.translate('logout')}
                          </Dropdown.Item>
                          <Dropdown.Item className={'c-font-xs'} href={'/profile'}>
                            {this.props.translate('profile')}
                          </Dropdown.Item>
                        </>
                      ) : (
                        <>
                          <Dropdown.Item className={'c-font-xs'} href='/login'>
                            {this.props.translate('login')}
                          </Dropdown.Item>
                          <Dropdown.Item className={'c-font-xs'} href='/register'>
                            {this.props.translate('register')}
                          </Dropdown.Item>
                        </>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* cart icon on mobile */}
                  <span>
                    <FontAwesomeIcon
                      size='2x'
                      icon={faShoppingCart}
                      className={this.props.cart.cart.products.length > 0 ? 'success-color d-sm-none' : 'grey-color d-sm-none'}
                      onClick={() => this.setState({ goToCart: true })}
                    />
                    {Object.keys(user).length > 0 && user['is_teammate'] === 1 ? (
                      <span className='badge d-sm-none  ml-2 dir-rtl shopping-number' style={{ bottom: '31px' }}>
                        <Translate id='weight_v2' data={{ weight: this.props.cart.cart_report.total_weight }} />
                      </span>
                    ) : (
                      <span className='badge badge-danger ml-2 d-sm-none shopping-number'>{products_number}</span>
                    )}
                  </span>
                </ButtonToolbar>
              </Col>

              {/* search */}
              <Col xs={7} md={7} lg={5}>
                {['text'].map((type, index_r) => (
                  <Form.Row key={index_r} className={'form-search-header'}>
                    <InputGroup>
                      <InputGroup.Prepend className='main-color main-bg-color' style={{ position: 'relative', zIndex: '10' }}>
                        <Link
                          className={'border-0'}
                          to={{
                            pathname: '/store',
                            state: { filter: { type: 'text', val: this.state.searchText } }
                          }}
                        >
                          <InputGroup.Text
                            className='main-bg-color border-0'
                            id='inputGroupPrepend'
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginRight: '-50%',
                              margin: '0',
                              height: '100%',
                              zIndex: '1',
                              transform: 'translate(-50%, -50%)',
                              borderRadius: '.25rem 0 0 .25rem'
                            }}
                          >
                            <FontAwesomeIcon className={'border-0 search-font'} icon={faSearch} />
                          </InputGroup.Text>
                        </Link>
                      </InputGroup.Prepend>
                      <Form.Control
                        autoComplete='off'
                        name='username'
                        type='text'
                        dir='rtl'
                        className='c-font-sm rounded-right search-input'
                        aria-describedby='inputGroupPrepend'
                        placeholder={this.props.translate('search')}
                        value={this.state.searchText}
                        onChange={e => {
                          this.setState({ searchText: convertPersianNumberToEnglish(e.target.value) });
                        }}
                        onKeyPress={this.handleKeyPress.bind(this)}
                      />
                    </InputGroup>
                  </Form.Row>
                ))}
              </Col>

              {/* logo */}
              <Col sm={12} lg={3} className='order-first order-lg-last text-center text-lg-right mb-1 mb-lg-0 '>
                <div className='menu-slide d-lg-none position-absolute' onClick={this.openSideBar.bind(this)}>
                  <FontAwesomeIcon icon={faBars} />
                </div>

                <Row style={{ paddingBottom: '5px' }}>
                  <Col style={{ width: '50%', paddingLeft: window.innerWidth < 768 ? '50px' : '40px' }}>
                    <Link to='/'>
                      <Image className='logo-app-bar' src={logo} />
                    </Link>
                  </Col>
                  <Col
                    style={{
                      width: '50%',
                      paddingRight: window.innerWidth < 768 ? '60px' : '30px',
                      fontSize: window.innerWidth < 768 ? '9px' : '.7rem',
                      textAlign: 'center',
                      marginTop: '5px'
                    }}
                  >
                    <span style={{ paddingTop: '5px' }}>{'بررسی ، مقایسه ، انتخاب و '}</span>
                    <br />
                    <span style={{ fontWeight: 'bold', fontSize: '15px', paddingTop: '5px' }}>{'خرید آنلاین طلا'}</span>
                  </Col>
                </Row>

                <div className={'d-lg-none position-absolute go-back '}>
                  <button className='btn btn-link' onClick={() => this.props.history.goBack()}>
                    <FontAwesomeIcon icon={faCaretLeft} />
                  </button>
                </div>
              </Col>
            </Row>

            {/* navigation mobile */}
            <Row className='d-sm-none justify-content-between pt-3 px-3 pb-2 align-items-center rtl lg-gutters c-font-sm mobile-navigation-bar'>
              <div>
                <FontAwesomeIcon icon={faChevronLeft} style={{ marginLeft: '5px' }} />
                {this.isNumeric(location[2] ? location[2] : location[1] ? location[1] : 'home')}
              </div>

              <div className='flex-row'>
                <span>{translate('gold_price')}:</span>
                <Translate
                  id='price_v'
                  data={{
                    price: <NumberFormat value={this.props.gold / 10} displayType={'text'} thousandSeparator={true} />
                  }}
                />
              </div>
            </Row>

            {Object.keys(user).length > 0 && (
              <Row className='d-sm-none justify-content-between pt-3 px-3 pb-2 align-items-center rtl lg-gutters c-font-sm mobile-navigation-bar'>
                <div>
                  <i className='fa-wallet fa' style={{ fontSize: '23px' }} />
                </div>

                <div className='d-flex flex-column'>
                  <span>
                    اعتبار شما : <NumberFormat value={this.props.credit / 10} displayType={'text'} suffix={' تومان '} thousandSeparator={true} />
                  </span>

                  <Link to='/profile/wallet'>+ افزایش اعتبار</Link>
                </div>
              </Row>
            )}
          </Container>
        </div>

        {/* blue navbar desktop */}
        <div className='bg-dark-grey d-none d-lg-block c-font-xs'>
          <Container fluid={true} style={{ maxWidth: '1366px' }}>
            <Navbar className='rtl justify-content-end p-0 bg-dark-grey' expand='lg'>
              <Nav className='ml-auto'>
                {/* <Nav.Item className="min-wv-15 main-menu-cat"> */}
                {/*<Nav.Item className="main-menu-cat main-light-color">*/}
                {/*    <NavDropdown className="rtl" style={{padding: '0', fontWeight: 'bold'}} title={*/}
                {/*        <span>*/}
                {/*            /!* <FontAwesomeIcon className="ml-1" icon={faAlignRight} /> *!/*/}
                {/*            <Translate id="categories" />*/}
                {/*        </span>}*/}
                {/*        id="basic-nav-dropdown">*/}
                {/*        {categories.map(s => {*/}
                {/*            return <Dropdown.Item className="c-font-xs c-sub-cat main-color" key={s.name} eventKey="1" style={{ fontSize: '15px', padding: '.5rem 1.5rem' }}>*/}
                {/*                <Link to={{ pathname: "/store", state: { filter: { type: 'category', val: s.id } } }} >*/}
                {/*                    {s.name}*/}
                {/*                </Link>*/}
                {/*            </Dropdown.Item>*/}
                {/*        })}*/}
                {/*    </NavDropdown>*/}
                {/*</Nav.Item>*/}
                <Nav.Item style={{ paddingTop: '.5rem' }}>
                  <Link className='main-light-color bold' to='/store'>
                    {translate('store')}
                  </Link>
                </Nav.Item>
                {/*<Nav.Item>*/}
                {/*    <Nav.Link className="main-light-color bold" href="/brands">{translate("brands")}</Nav.Link>*/}
                {/*</Nav.Item>*/}
                <Nav.Item>
                  <Nav.Link className='main-light-color bold' href='/policy'>
                    {translate('policy')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className='main-light-color bold' href={localStorage.getItem('token') ? '/profile/new-ticket' : '/login'}>
                    {translate('support')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className='main-light-color bold' href='/about'>
                    {translate('about_us')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className='main-light-color bold' href='/contact'>
                    {translate('contact_us')}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Nav>
                <Nav.Item className='self-align-end color-white'>
                  <span>{translate('gold_price')}:</span>
                  <Translate
                    id='price_v'
                    data={{
                      price: <NumberFormat value={this.props.gold / 10} displayType={'text'} thousandSeparator={true} />
                    }}
                  />
                </Nav.Item>
              </Nav>
            </Navbar>
          </Container>
        </div>

        <div className='side-nav-menu position-fixed text-right ' style={{ width: this.state.width + 'vw' }}>
          <div className='flex align-items-center p-1 text-center top-img-wrapper '>
            <span style={{ position: 'absolute', fontSize: '30px', color: 'black', paddingRight: '10px', right: '0' }} onClick={this.openSideBar.bind(this)}>
              &times;
            </span>

            <Link to='/' className='slide-logo' onClick={this.openSideBar.bind(this)}>
              <Image className='img-fluid' src={slide_logo} />
            </Link>

            <span className={'mx-auto mt-4'}>{this.props.user ? (this.props.user.name ? this.props.user.name + ' ' + this.props.user.family : this.props.user.mobile) : null}</span>

            {Object.keys(this.props.user).length > 0 && (
              <div style={{ bottom: '0', right: '5px', fontSize: '12px' }}>
                <span>{translate('gold_price')}:</span>
                <Translate
                  id='price_v'
                  data={{
                    price: <NumberFormat value={this.props.gold / 10} displayType={'text'} thousandSeparator={true} style={{ fontWeight: 'bold' }} />
                  }}
                />
              </div>
            )}
          </div>

          {this.props.cart_credit > 0 && (
            <div className='bg-success color-white rtl'>
              <span>{translate('credit')}:</span>
              <NumberFormat displayType={'text'} thousandSeparator={true} value={this.props.credit / 10} />
            </div>
          )}

          <div style={{ padding: '10px' }}>
            <Link
              to='/'
              onClick={this.openSideBar.bind(this)}
              className='menu-mobile-item d-block text-right rtl c-font-md'
              aria-controls='example-collapse-text'
              aria-expanded={this.state.open}
            >
              <FontAwesomeIcon className='mr-2 ml-1' icon={faHome} />
              <span>{translate('home')}</span>
            </Link>
            <Link
              to='/store'
              onClick={this.openSideBar.bind(this)}
              className='menu-mobile-item d-block text-right rtl c-font-md'
              aria-controls='example-collapse-text'
              aria-expanded={this.state.open}
            >
              <FontAwesomeIcon className='mr-2 ml-1' icon={faShoppingBag} />
              <span>{translate('store')}</span>
            </Link>
            <Link to={localStorage.getItem('token') ? '/profile/new-ticket' : '/login'} className='menu-mobile-item d-block text-right rtl c-font-md'>
              <FontAwesomeIcon className='mr-2 ml-1' icon={faHeadset} />
              <span>{translate('support')}</span>
            </Link>
            <Link className='menu-mobile-item d-block text-right rtl c-font-md' to='/about'>
              <FontAwesomeIcon className='mr-2 ml-1' icon={faLayerGroup} />
              {translate('about_us')}
            </Link>
            <Link className='menu-mobile-item d-block text-right rtl c-font-md' to='/contact'>
              <FontAwesomeIcon className='mr-2 ml-1' icon={faPhone} />
              {translate('contact_us')}
            </Link>
            {/* <div
              onClick={() => this.setState({ openCategory: !openCategory, openBrand: false })}
              aria-controls='example-collapse-text-cat'
              aria-expanded={openCategory}
              className='menu-mobile-item d-block text-right rtl c-font-md'
            >
              {openCategory && <FontAwesomeIcon className='mr-2 ml-1' icon={faMinus} />}
              {!openCategory && <FontAwesomeIcon className='mr-2 ml-1' icon={faPlus} />}
              <span>{'دسته بندی'}</span>
            </div> */}
            {/* <Collapse in={this.state.openCategory}>
              <div id='example-collapse-text-cat' onClick={() => this.openSideBar.bind(this)}>
                <ul className='slider category-list rtl'>
                  <li className='category'>
                    <div className='cat-name align-items-center'>
                      <Link to='/store' className='d-inline font-weight-light color-white '>
                        <Translate id={'all'} />
                      </Link>
                      <FontAwesomeIcon size='sm' className='d-inline mr-1 font-weight-light' color='white' icon={faLayerGroup} />
                    </div>
                    {categories.length > 0 && (
                      <ul className='sub-cat-menu'>
                        {categories.map((s) => {
                          let id = s.id;
                          return (
                            <li key={id} className='sub-cats-item c-font-sm border-bottom' onClick={this.openSideBar.bind(this)}>
                              <Link to='/store'>{s.name}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                </ul>
              </div>
            </Collapse> */}
            {/* <div
              onClick={() => this.setState({ openBrand: !openBrand, openCategory: false })}
              aria-controls='example-collapse-text-brand'
              aria-expanded={openBrand}
              className='menu-mobile-item d-block text-right rtl c-font-md'
            >
              {openBrand && <FontAwesomeIcon className='mr-2 ml-1' icon={faMinus} />}
              {!openBrand && <FontAwesomeIcon className='mr-2 ml-1' icon={faPlus} />}
              <span>{this.props.translate('brands')}</span>
            </div> */}
            {/* <Collapse in={this.state.openBrand}>
              <div id='example-collapse-text-brand' onClick={() => this.openSideBar.bind(this)}>
                <ul className='slider category-list rtl'>
                  <li className='category'>
                    <div className='cat-name align-items-center'>
                      <Link to={'/brands'} className='d-inline font-weight-light color-white '>
                        <Translate id={'all'} />
                      </Link>
                      <FontAwesomeIcon size='sm' className='d-inline mr-1 font-weight-light' color='white' icon={faLayerGroup} />
                    </div>
                    {this.state.brands.length > 0 && (
                      <ul className='sub-cat-menu'>
                        {this.state.brands.map((s) => {
                          let id = s.id;
                          return (
                            <li key={id} className='sub-cats-item c-font-sm border-bottom' onClick={this.openSideBar.bind(this)}>
                              <Link to='/store'>{s.brand}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                </ul>
              </div>
            </Collapse> */}
          </div>
        </div>

        <div onClick={this.openSideBar.bind(this)} className='layer position-fixed' style={{ display: this.state.display }} />

        {/* navigation desktop */}
        <Row className='d-none d-sm-block align-items-center rtl desktop-navigation-bar m-0'>
          <div className='desktop-navigation-bar-p d-flex align-items-center justify-content-between w-100'>
            <div>
              <FontAwesomeIcon icon={faChevronLeft} style={{ marginLeft: '5px' }} />
              {this.isNumeric(location[2] ? location[2] : location[1] ? location[1] : 'home')}
            </div>

            {Object.keys(user).length > 0 && (
              <div className='d-flex flex-column'>
                <span>
                  اعتبار شما : <NumberFormat value={this.props.credit / 10} displayType={'text'} suffix={' تومان '} thousandSeparator={true} />
                </span>

                <Link to='/profile/wallet'>+ افزایش اعتبار</Link>
              </div>
            )}
          </div>
        </Row>
      </div>
    );
  }
}

const MapStateToProps = state => ({
  translate: getTranslate(state.localize),
  user: state.user,
  filters: state.filters,
  errors: state.request_error,
  cart: state.cart,
  gold: state.gold_price,
  credit: state.cart.cart_credit,
  brands: state.brands,
  categories: state.categories
});

export default withRouter(
  connect(MapStateToProps, { set_me, getBasket, set_user, subcategory_setter, logout_user, errorAction, set_all_categories, set_all_brands, mandeHesab_setter })(AppBar)
);
