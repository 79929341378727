// 15191
import React, { Suspense, lazy, useEffect } from 'react';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize } from 'react-localize-redux';
import * as axios from 'axios';
import { createBrowserHistory } from 'history';
import { Toast } from 'react-bootstrap';
import { connect } from 'react-redux';

import Loader from './blocks/Loader';
import { notNullAndUndefined } from './helper';
import { offline_mode } from './actions/cartActions';
import BrandPage from './views/BrandPage';
import NewsAll from './views/NewsAll';
import SingleNews from './views/SingleNews';
import PWAModal from './blocks/PwaModal';
import InfoModal from './blocks/InfoModal';
// import EMTAModal from './blocks/EmtaModal';
import { set_all_brands } from './actions/brands';
import { set_all_categories } from './actions/categories';
import translations from './translations/fa.json';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './assets/css/custom.css';

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'production') console.log = function () {};

const AddressPage = lazy(() => import('./views/AddressPage'));
const SuccessAdminOrderPage = lazy(() => import('./views/SuccessAdminOrderPage'));
const Home = lazy(() => import('./views/Home'));
const SingleProduct = lazy(() => import('./views/SingleProduct'));
const StorePlace = lazy(() => import('./views/Store'));
const Register = lazy(() => import('./views/Register'));
const Login = lazy(() => import('./views/Login'));
const Forgot = lazy(() => import('./views/Forgot'));
const Cart = lazy(() => import('./views/Cart'));
const NotFound = lazy(() => import('./views/404'));
const Confirm = lazy(() => import('./views/Confirm'));
const Profile = lazy(() => import('./views/Profile'));
const Success = lazy(() => import('./views/Success'));
const Failed = lazy(() => import('./views/Failed'));
const Successwallet = lazy(() => import('./views/SuccessWallet'));
const ContactUs = lazy(() => import('./views/ContactUs'));
const AboutUs = lazy(() => import('./views/AboutUs'));
const Policy = lazy(() => import('./views/Policy'));

const history = createBrowserHistory();

const isAuthenticated = () => {
  if (localStorage.getItem('token') !== 'undefined' && notNullAndUndefined(localStorage.getItem('token'))) {
    return true;
  }

  return false;
};

function PrivateRoute ({ component: Component, ...rest }) {
  useEffect(() => {
    const res_intercept = axios.interceptors.response.use(undefined, function (error) {
      if (typeof error.response !== 'undefined') {
        if (error.response.status === 401) {
          history.push('/login');
        }
      }

      return Promise.reject(error);
    });

    return () => {
      axios.interceptors.request.eject(res_intercept);
    };
  }, []);

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

export const req_intercept = axios.interceptors.request.use(config => ({
  ...config,
  headers: { ...config.headers, Authorization: `Bearer ${localStorage.getItem('token')}` }
}));

class App extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      show_toast: false,
      show_toast_success: false,
      errors: null,
      success: null,
      toast_d_none: 'd-none h-0',
      toast_success_none: 'd_none h-0',
      showPWA: false,
      showInfo: false,
      showEMTA: false
    };
    props.initialize({
      languages: [{ name: 'Persian', code: 'fa' }],
      translation: translations,
      options: { renderToStaticMarkup }
    });

    this.handleClosePWA = this.handleClosePWA.bind(this);
    this.handleCloseInfo = this.handleCloseInfo.bind(this);
    this.handleShowPWA = this.handleShowPWA.bind(this);
    this.handleShowInfo = this.handleShowInfo.bind(this);
    this.handleCloseEMTA = this.handleCloseEMTA.bind(this);
    this.handleShowEMTA = this.handleShowEMTA.bind(this);
  }

  componentDidMount () {
    if (Object.keys(this.props.user).length > 0) {
      this.props.offline_mode(false);
    }
    // this.props.set_all_brands();
    // this.props.set_all_categories();
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.errors !== this.props.errors) {
      if (typeof this.props.errors === 'object') {
        this.setState({ errors: JSON.stringify(this.props.errors.er), show_toast: true, toast_d_none: '' });
      }
    }

    if (prevProps.success_message !== this.props.success_message) {
      this.setState({
        success: this.props.success_message.msg,
        show_toast_success: true,
        toast_success_none: ''
      });
    }

    if (prevProps.user.emta_validate !== this.props.user.emta_validate) {
      this.setState({
        showEMTA: this.props.user.emta_validate === 0 ? true : false
      });
    }
  }

  handleShowPWA () {
    this.setState({
      showPWA: true
    });
  }

  handleShowInfo () {
    this.setState({
      showInfo: true
    });
  }

  handleClosePWA () {
    this.setState({
      showPWA: false
    });
    localStorage.setItem('showPWA', false);
  }

  handleCloseInfo () {
    this.setState({
      showInfo: false
    });
    localStorage.setItem('showInfo', false);
  }

  handleShowEMTA () {
    this.setState({
      showEMTA: true
    });
  }

  handleCloseEMTA () {
    this.setState({
      showEMTA: false
    });
    localStorage.setItem('showEMTA', false);
  }

  render () {
    let { showPWA, showInfo } = this.state;
    const pwa = localStorage.getItem('showPWA');
    const info = localStorage.getItem('showInfo');
    const emta = localStorage.getItem('showEMTA');
    // let location = useLocation();
    // let modalProductPage = location.state && location.state.modalProductPage;

    if (!pwa) {
      localStorage.setItem('showPWA', true);
      this.setState({
        showPWA: true
      });
    }
    if (!info) {
      localStorage.setItem('showInfo', true);
      this.setState({
        showInfo: true
      });
    }

    if (this.props.user && emta === true) {
      localStorage.setItem('showEMTA', true);
      this.setState({
        showEMTA: true
      });
    }

    return (
      <div>
        {window.innerWidth <= 768 && showPWA && <PWAModal showModal={this.handleShowPWA} onHide={this.handleClosePWA} />}
        {window.innerWidth <= 768 && !showPWA && showInfo && <InfoModal showModal={this.handleShowInfo} onHide={this.handleCloseInfo} />}
        {window.innerWidth > 768 && showInfo && <InfoModal showModal={this.handleShowInfo} onHide={this.handleCloseInfo} />}

        {/* {window.innerWidth <= 768 && showEMTA && <EMTAModal showModal={this.handleShowEMTA} onHide={this.handleCloseEMTA} />} */}

        <Toast
          className={'position-fixed bg-danger color-white z-index-top-toast c-toast-glob' + this.state.toast_d_none}
          onClose={() => this.setState({ show_toast: false, toast_d_none: 'd-none h-0' })}
          show={this.state.show_toast}
          delay={4000}
          autohide
          style={{ zIndex: '999999999999999999999' }}
        >
          <Toast.Body>{this.state.errors}</Toast.Body>
        </Toast>

        <Toast
          style={{ zIndex: '999999999999999999999' }}
          className={'z-index-first position-fixed bg-success color-white z-index-top-toast c-toast-glob danger-position ' + this.state.toast_success_none}
          onClose={() => this.setState({ show_toast_success: false, toast_success_none: 'd-none h-0' })}
          show={this.state.show_toast_success}
          delay={3000}
          autohide
        >
          <Toast.Body>{this.state.success}</Toast.Body>
        </Toast>

        <Router history={history}>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route exact path='/' component={StorePlace} />
              <Route path='/store' component={StorePlace} />
              <Route path='/brands' component={BrandPage} />
              <Route path='/cart' component={Cart} />
              <Route path='/login'>
                <Login />
              </Route>
              <Route path='/register'>
                <Register />
              </Route>
              <Route path='/forgot'>
                <Forgot />
              </Route>
              <Route path='/news'>
                <NewsAll />
              </Route>
              <Route path='/single_news'>
                <SingleNews />
              </Route>
              <Route path='/profile'>
                <Profile />
              </Route>
              <Route path='/products/:id/:name' component={SingleProduct} />
              <Route path='/products/:id' component={SingleProduct} />
              <Route path='/contact' component={ContactUs} />
              <Route path='/about' component={AboutUs} />
              <Route path='/policy' component={Policy} />
              <PrivateRoute path='/addressPage' component={AddressPage} />
              <PrivateRoute path='/success-order' component={SuccessAdminOrderPage} />
              <PrivateRoute path='/confirm'>
                <Confirm />
              </PrivateRoute>
              <PrivateRoute path='/success'>
                <Success />
              </PrivateRoute>
              <PrivateRoute path='/success-wallet'>
                <Successwallet />
              </PrivateRoute>
              <PrivateRoute path='/failed'>
                <Failed />
              </PrivateRoute>
              <Route path='*' status={404}>
                <NotFound />
              </Route>
            </Switch>
          </Suspense>
        </Router>
      </div>
    );
  }
}

const MapStateToProps = state => ({
  errors: state.request_error,
  success_message: state.success_message,
  user: state.user
});

export default withLocalize(connect(MapStateToProps, { offline_mode, set_all_brands, set_all_categories })(App));
